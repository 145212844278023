import {
  Breadcrumbs,
  Collapse,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import useSWR from "swr";
import { fetcher } from "../utils/functions";

import Skeleton from "@mui/material/Skeleton";
import { getUsers } from "../actions/users";
import DbLayout from "./DbLayout";
import DbEntry from "./DbEntry";

const DbRevisions = ({ users, getUsers, approved, rejected }) => {
  const [open, setOpen] = useState(null);

  useEffect(() => {
    if (!users || !users.length) getUsers();
  }, []);

  const PREFIX = "DbRevisions";

  const classes = {
    table: `${PREFIX}-table`,
    tableHead: `${PREFIX}-tableHead`,
    progress: `${PREFIX}-progress`,
    modalPaper: `${PREFIX}-modalPaper`,
    tableRow: `${PREFIX}-tableRow`,
    tableRowOpen: `${PREFIX}-tableRowOpen`,
  };

  // TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
  const Root = styled("div")({
    [`& .${classes.table}`]: {
      minWidth: 650,
      marginTop: 20,
    },
    [`& .${classes.tableHead}`]: {
      backgroundColor: "#ddd",
    },
    [`& .${classes.progress}`]: {
      position: "absolute",
      top: "60px",
      right: "20px",
    },
    [`& .${classes.modalPaper}`]: {
      width: "10%",
    },
    [`& .${classes.tableRow}`]: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f4f4f4",
      },
    },

    [`& .${classes.tableRowOpen}`]: {
      backgroundColor: "#f4f4f4",
    },
  });

  const {
    data: revisions,
    error: error,
    isLoading: loading,
    mutate,
  } = useSWR(
    process.env.REACT_APP_BACKEND_URL + "/database/revisions",
    fetcher
  );

  const [selectedJurisdictionName, setSelectedJurisdictionName] = useState("");

  const [sortBy, setSortBy] = useState({ by: "id", desc: true });

  if (error) return <div>failed to load</div>;
  if (loading)
    return (
      <Container>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Skeleton
            sx={{ bgcolor: "grey.100" }}
            style={{ marginBottom: "20px" }}
            variant="rectangular"
            width={"calc(100vw - 300px)"}
            height={42}
          />
          <Skeleton
            sx={{ bgcolor: "grey.100" }}
            variant="rectangular"
            width={"calc(100vw - 300px)"}
            height={"calc(100vh - 310px)"}
          />
        </Paper>
      </Container>
    );

  if (approved || rejected)
    return (
      <DbLayout>
        <div>
          <Container>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              Coming Soon
            </Paper>
          </Container>
        </div>
      </DbLayout>
    );

  return (
    <Root>
      <DbLayout>
        <div>
          <Container>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="text.primary">Pending Review</Typography>
            </Breadcrumbs>
          </Container>
          {revisions && revisions.revisions && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell
                      onClick={() =>
                        setSortBy({ by: "entry_type_id", desc: !sortBy.desc })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Area
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        setSortBy({ by: "jurisdiction_id", desc: !sortBy.desc })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Jurisdiction
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        setSortBy({ by: "updated_at", desc: !sortBy.desc })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Date Submitted
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        setSortBy({ by: "user_id", desc: !sortBy.desc })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Submitted By
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {revisions.revisions.map((entry) => (
                    <>
                      <TableRow
                        key={entry.id}
                        onClick={() =>
                          open !== entry.id ? setOpen(entry.id) : setOpen(null)
                        }
                        className={
                          classes.tableRow +
                          " " +
                          (open === entry.id ? classes.tableRowOpen : "")
                        }
                      >
                        <TableCell>{entry.entries.entry_type_id}</TableCell>

                        <TableCell>
                          {entry.entries.jurisdictions.name_en}
                          {entry.entries.subjurisdictions &&
                          entry.entries.subjurisdictions.name_en
                            ? " - " + entry.entries.subjurisdictions.name_en
                            : ""}
                        </TableCell>

                        <TableCell>
                          {new Date(entry.updated_at).toLocaleDateString() +
                            " " +
                            new Date(entry.updated_at).toLocaleTimeString()}
                        </TableCell>
                        <TableCell>
                          {users &&
                            users.find((u) => u.id === entry.user_id) &&
                            users.find((u) => u.id === entry.user_id).name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            in={open === entry.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <DbEntry
                              entryTypeId={entry.entries.entry_type_id}
                              jurisdictionId={entry.entries.jurisdictions.id}
                              subjurisdictionId={
                                entry.entries.subjurisdictions?.id
                              }
                              setSuccess={() => {}}
                              fromRevisions={true}
                            />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </DbLayout>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  users: state.users.all,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => dispatch(getUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DbRevisions);
