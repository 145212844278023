import { Icon, Drawer as MuiDrawer } from "@mui/material";
import { grey } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import TreeMenu from "./Components/TreeMenu";
import "./styles.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AlertSnackbarProvider } from "./Components/AlertSnackbarProvider";

const styles = {
  drawer: {
    width: 240,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 240,
      top: "5rem",

      backgroundColor: "#fff",
      color: "#333",
      borderRight: "2px solid #4c83b0",
    },
  },
  main: {
    flexShrink: 0,
    paddingLeft: 245,

    position: "relative",
  },
  li: {
    marginBottom: "0.5rem",
    cursor: "pointer",
    // Add a pointer on hover
    "&:hover": { textDecoration: "underline" },
  },

  ul: {
    marginTop: "2rem",
    listStyleType: "none",
    marginLeft: "-1rem",
  },
  icon: { marginRight: "0.5rem" },
  spanLink: {
    cursor: "pointer",
    // Add a pointer on hover
    "&:hover": { textDecoration: "underline", background: "#efefef" },
  },
  linkMenuTitle: {
    color: "#4c83b0",
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
};

const theme = createTheme({
  palette: {
    background: {
      default: "#fff", // Or any color you want for the body background
    },
  },
});

export default function DbLayout({ children }) {
  const history = useHistory();
  return (
    <ThemeProvider theme={theme}>
      <AlertSnackbarProvider>
        <CssBaseline />
        <MuiDrawer
          variant="persistent"
          anchor="left"
          open={true}
          sx={styles.drawer}
        >
          <div style={{ ...styles.sidebar }}>
            <ul style={{ ...styles.ul }}>
              <li
                style={{ ...styles.li }}
                onClick={() => {
                  history.push(`/db-dashboard`);
                }}
              >
                <Icon className="linkAction">edit_node</Icon>
                <span className="linkMenu">New Edit</span>
              </li>

              <li
                style={{ ...styles.li }}
                onClick={() => {
                  history.push(`/db-drafts`);
                }}
              >
                <Icon className="linkAction">pending_actions</Icon>
                <span className="linkMenu">Working Drafts</span>
              </li>
              <li
                style={{ ...styles.li }}
                onClick={() => {
                  history.push(`/db-revisions-rejected`);
                }}
              >
                <Icon className="linkAction">assignment_return</Icon>
                <span className="linkMenu">Returned Revisions</span>
              </li>

              <li
                style={{ ...styles.li }}
                onClick={() => {
                  history.push(`/db-revisions`);
                }}
              >
                <Icon className="linkAction">publish</Icon>
                <span className="linkMenu">Sent for Revision</span>
              </li>
              <li
                style={{ ...styles.li }}
                onClick={() => {
                  history.push(`/db-revisions-approved`);
                }}
              >
                <Icon className="linkAction">emoji_events</Icon>
                <span className="linkMenu">Approved Entries</span>
              </li>

              <li
                style={{ ...styles.li }}
                onClick={() => {
                  history.push(`/db-revisions-rejected`);
                }}
              >
                <Icon className="linkAction">query_stats</Icon>
                <span className="linkMenu">Your Stats</span>
              </li>
            </ul>

            <ul style={{ ...styles.ul }}>
              <li style={{ ...styles.linkMenuTitle }}>
                <span>Proofreader</span>
              </li>
              <li
                style={{ ...styles.li }}
                onClick={() => {
                  history.push(`/db-revisions`);
                }}
              >
                <Icon className="linkAction">publish</Icon>
                <span className="linkMenu">Pending Revisions</span>
              </li>
              <li
                style={{ ...styles.li }}
                onClick={() => {
                  history.push(`/db-revisions`);
                }}
              >
                <Icon className="linkAction">download</Icon>
                <span className="linkMenu">Rejected Revisions</span>
              </li>
            </ul>

            <ul style={{ ...styles.ul }}>
              <li style={{ ...styles.linkMenuTitle }}>
                <span>Admin</span>
              </li>
              <li
                style={{ ...styles.li }}
                onClick={() => {
                  history.push(`/db-revisions`);
                }}
              >
                <Icon className="linkAction">publish</Icon>
                <span className="linkMenu">Revisions to approve</span>
              </li>
              <li
                style={{ ...styles.li }}
                onClick={() => {
                  history.push(`/db-revisions`);
                }}
              >
                <Icon className="linkAction">publish</Icon>
                <span className="linkMenu">All Pending Revisions</span>
              </li>
              <li
                style={{ ...styles.li }}
                onClick={() => {
                  history.push(`/db-drafts-all`);
                }}
              >
                <Icon className="linkAction">pending_actions</Icon>
                <span className="linkMenu">All Drafts</span>
              </li>
            </ul>
          </div>
        </MuiDrawer>
        <div style={{ ...styles.main }}>{children}</div>
      </AlertSnackbarProvider>
    </ThemeProvider>
  );
}
