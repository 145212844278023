import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import useSWR, { useSWRConfig } from "swr";
import TabPanel from "./TabPanel";
import { LocalizationProvider } from "@mui/x-date-pickers";
import YearPicker from "./YearPicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomizedSelect from "./Selects/CustomizedSelect";
import { formDataLoader } from "../Config/area1";
import RichTextTap from "./TipTap/RichTextTap";
import ExplanTabPanel from "./ExplanTabPanel";
import TipTapContext from "../../shared/context/tipTap-context";
import { connect, useSelector } from "react-redux";
import { getLanguages, getSourceTypes, getTags } from "../../actions/related";
import { getAllSubJurisdictions } from "../../actions/selectOptions";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  ActionTriggerProvider,
  useActionTrigger,
} from "../ActionTriggerContext";
import AlertSnackbarContext from "./AlertSnackbarContext";

const postFetcherSWR = async (url, data) => {
  const response = await axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = response;
  return responseData;
};
function GenericForm({
  mutate,
  form,
  jurisdictionId,
  entryTypeId,
  subjurisdictionId,
  checkErrors,
  initialFormState,
  dataEntry,
  enabled,
  isLoadingEntry,
  embedded = false,
  fromRevisions = false,
  allJurisdictions,
}) {
  const { mutate: globalMutate } = useSWRConfig();
  const location = useLocation();
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [errorSources, setErrorSources] = useState("");
  const sourcesValidation = useSelector((state) => state.sourcesValidation);
  const [jurisdictionOptions, setJurisdictionOptions] = useState([]);

  const { openSnackbar, closeSnackbar } = useContext(AlertSnackbarContext);
  const {
    changeEntry,
    changeCtxJurisdictions,
    addingNewFootnote,
    addingNewSource,
    setAddingNewFootnote,
    saveChanges,
  } = useContext(TipTapContext);
  const { triggerAction, acknowledgedBy } = useActionTrigger();

  useEffect(() => {
    if (dataEntry) changeEntry(dataEntry.entry[0]);
  }, [dataEntry]);

  useEffect(() => {
    setFormData(
      formDataLoader[entryTypeId](
        formData,
        dataEntry ? dataEntry.entry[0] : null
      )
    );
  }, [entryTypeId, dataEntry]);

  useEffect(() => {
    console.log("FORMDATA", formData);
  }, [formData]);

  const entry =
    dataEntry && dataEntry.entry && dataEntry.entry.length
      ? dataEntry.entry[0]
      : "";

  const [sources, setSources] = useState(entry.entries.sources);

  useEffect(() => {
    // global state =>
    window.desources = sources;
    console.log("entry.entries: ", entry.entries);
  }, [entry.entries.sources]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get("tab");

    if (tabParam) {
      setActiveTab(form.tabs.map((t) => t.id).indexOf(tabParam));
    }
  }, []);

  useEffect(() => {
    console.log("ALLL JURS", allJurisdictions);
    if (allJurisdictions && allJurisdictions.jurisdictions) {
      changeCtxJurisdictions(allJurisdictions.jurisdictions);
      const jurOptions = allJurisdictions.jurisdictions.map((item) => ({
        label: item.name_en,
        value: {
          value: item.id,
          label: item.name_en,
          countryCode: item.a2_code,
        },
      }));
      console.log("ALLL JURS 222", jurOptions);
      setJurisdictionOptions(jurOptions);
    }
  }, [allJurisdictions]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const changeStyles = (field) => {
    if (errors.includes(field.name)) {
      return { border: "1px solid red" };
    } else {
      let isRichText = field.type === "richtext" || field.type === "sourcetext";
      let modified = false;
      if (field.type === "sourcetext") {
        modified =
          dataEntry &&
          dataEntry.entry[0] &&
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child[field.name + "_en"] !=
            formData[field.name + "_en"];
      } else {
        modified =
          dataEntry &&
          dataEntry.entry[0] &&
          dataEntry.entry[0].original_child &&
          dataEntry.entry[0].original_child[field.name] != formData[field.name];
      }
      if (modified) {
        return { backgroundColor: "#ffffcf" };
      }
    }
  };

  const handleYearChange = (name, year) => {
    console.log({ ...formData, [name]: year });
    setFormData({ ...formData, [name]: year });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCustomizableSelectChange = (field, value) => {
    //setSelectedOtherPunishment(value);
    console.log(field, value);
    setFormData({ ...formData, [field]: value });
  };

  const handleCheckboxChange = (event, name) => {
    setFormData({ ...formData, [name]: event.target.checked });
  };

  const handleFormSubmit = async (
    e,
    draft = false,
    upload = false,
    sent_for_revision = false
  ) => {
    e.preventDefault();

    triggerAction();

    const errorsss = checkErrors(formData);

    if (errorsss.length > 0) {
      setErrors(errorsss);
      return false;
    } else {
      setErrors([]);
      try {
        await postFetcherSWR(
          `${process.env.REACT_APP_BACKEND_URL}/database/entry/${jurisdictionId}/${entryTypeId}/${subjurisdictionId}`,
          { ...formData, draft, upload, sent_for_revision }
        );
        // does a false positive on
        if (draft) openSnackbar("Draft saved successfully");
        if (upload) openSnackbar("Entry saved successfully");
        if (sent_for_revision) openSnackbar("Entry submitted for revision");

        globalMutate(
          `${process.env.REACT_APP_BACKEND_URL}/database/entry/${jurisdictionId}/${entryTypeId}/${subjurisdictionId}/0`
        );
      } catch (err) {
        openSnackbar({
          msg: "There was an error saving the entry",
          severity: "error",
        });
        console.log("err: ", err);
      }
    }
  };

  return (
    <form>
      {formData && (
        <Card sx={{ width: "100%" }}>
          <Card sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeTab}
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleTabChange}
              aria-label="Data entry form tabs"
            >
              {form.tabs.map((tab, index) => (
                <Tab label={tab.label} {...a11yProps(index)} />
              ))}
            </Tabs>
          </Card>

          {form.tabs.map((tab, index) => (
            <TabPanel value={activeTab} index={index} enabled={enabled}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom>
                    {tab.label}
                  </Typography>
                </Grid>
                {tab.fields.map((field) => {
                  if (field.conditional && !field.conditional(formData))
                    return null;
                  switch (field.type) {
                    case "filler":
                      return (
                        <Grid
                          item
                          xs={field.size}
                          sm={field.size}
                          md={field.size}
                        >
                          <div></div>
                        </Grid>
                      );
                      break;
                    case "text":
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          key={`${field.name}_${jurisdictionId}_${entryTypeId}`}
                          style={changeStyles(field)}
                        >
                          <Typography variant="h6">{field.label}</Typography>
                          <input
                            type="text"
                            name={field.name}
                            value={
                              formData[field.name] ? formData[field.name] : ""
                            }
                            onChange={handleInputChange}
                          />
                        </Grid>
                      );
                    case "textarea":
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          key={`${field.name}_${jurisdictionId}_${entryTypeId}`}
                          style={changeStyles(field)}
                        >
                          <Typography variant="h6">{field.label}</Typography>
                          <textarea
                            name={field.name}
                            value={
                              formData[field.name] ? formData[field.name] : ""
                            }
                            onChange={handleInputChange}
                          />
                        </Grid>
                      );
                    case "select":
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          style={changeStyles(field)}
                          key={`${field.name}_${jurisdictionId}_${entryTypeId}`}
                        >
                          <FormControl fullWidth>
                            <InputLabel htmlFor="legal">
                              {field.label}
                              {field.mandatory && "*"}
                            </InputLabel>
                            <Select
                              id={field.name}
                              name={field.name}
                              label={field.label}
                              value={
                                formData[field.name] !== undefined
                                  ? formData[field.name]
                                  : ""
                              }
                              onChange={handleInputChange}
                            >
                              {field.options.map((option) => (
                                <MenuItem value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      );
                    case "checkbox":
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          key={`${field.name}_${jurisdictionId}_${entryTypeId}`}
                          style={changeStyles(field)}
                        >
                          <FormControl component="fieldset">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      formData[field.name]
                                        ? formData[field.name]
                                        : false
                                    }
                                    onChange={(e) =>
                                      handleCheckboxChange(e, field.name)
                                    }
                                    name={field.name}
                                  />
                                }
                                label={field.label}
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      );
                    case "year":
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          key={`${field.name}_${jurisdictionId}_${entryTypeId}`}
                          style={changeStyles(field)}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <YearPicker
                              name={field.name}
                              label={field.label}
                              value={
                                formData[field.name]
                                  ? formData[field.name]
                                  : null
                              }
                              onChange={(value) => {
                                handleYearChange(
                                  field.name,
                                  value && value["$y"]
                                );
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      );
                    case "number":
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          key={`${field.name}_${jurisdictionId}_${entryTypeId}`}
                          style={changeStyles(field)}
                        >
                          <FormControl fullWidth>
                            <TextField
                              id={field.name}
                              name={field.name}
                              label={field.label}
                              type="number"
                              value={
                                formData[field.name] ? formData[field.name] : ""
                              }
                              onChange={handleInputChange}
                            />
                          </FormControl>
                        </Grid>
                      );
                    case "richtext":
                      return (
                        <Grid
                          item
                          xs={12}
                          xl={6}
                          key={`${field.name}_${jurisdictionId}_${entryTypeId}`}
                          style={changeStyles(field)}
                        >
                          <Typography variant="h6" gutterBottom>
                            {field.label}
                          </Typography>
                          <RichTextTap
                            content={
                              formData[field.name] ? formData[field.name] : ""
                            }
                            update={(thing) => {
                              setFormData({
                                ...formData,
                                [field.name]: thing,
                              });
                            }}
                          />
                        </Grid>
                      );
                    case "sourcetext":
                      return (
                        <Grid
                          item
                          xs={12}
                          key={`${field.name}_${jurisdictionId}_${entryTypeId}`}
                          style={changeStyles(field)}
                        >
                          <ExplanTabPanel
                            columnName={field.name}
                            formData={formData}
                            dataEntry={dataEntry}
                            mutate={mutate}
                            addingNewFootnote={addingNewFootnote}
                            setAddingNewFootnote={setAddingNewFootnote}
                            section={field.section}
                          />
                        </Grid>
                      );
                    default:
                      return (
                        <Grid item xs={12} xl={6}>
                          <Typography variant="h6">
                            Unknown field {field.type}
                          </Typography>
                        </Grid>
                      );
                  }
                })}
              </Grid>
            </TabPanel>
          ))}
        </Card>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {errorSources && <p className="form__errors">{errorSources}</p>}
          {errors.length > 0 ? (
            <span className="form__errors">
              Missing mandatory fields: {errors.join(", ")}
            </span>
          ) : (
            ""
          )}

          <div className="form__actions">
            {!fromRevisions ? (
              enabled && (
                <>
                  <Button
                    className="submit__btn draft__btn"
                    type="button"
                    onClick={(e) => handleFormSubmit(e, true, false)}
                    variant="contained"
                    disabled={
                      form.tabs.some((tab) => {
                        return tab.fields.some((field) => {
                          return (
                            sourcesValidation[field.name + "_en"] !==
                            sourcesValidation[field.name + "_es"]
                          );
                        });
                      }) ||
                      addingNewFootnote ||
                      addingNewSource
                    }
                  >
                    {isLoadingEntry ? "loading" : "Save Draft"}
                  </Button>

                  {activeTab !== 5 && (
                    <Button
                      className="submit__btn"
                      type="submit"
                      variant="contained"
                      onClick={(e) => handleFormSubmit(e, true, false, true)}
                      disabled={
                        form.tabs.some((tab) => {
                          return tab.fields.some((field) => {
                            return (
                              sourcesValidation[field.name + "_en"] !==
                              sourcesValidation[field.name + "_es"]
                            );
                          });
                        }) ||
                        addingNewFootnote ||
                        addingNewSource
                      }
                    >
                      {isLoadingEntry ? "loading" : "Submit for Revision"}
                    </Button>
                  )}
                </>
              )
            ) : (
              <>
                {activeTab !== 1 && (
                  <Button
                    className="submit__btn reject__btn"
                    type="button"
                    onClick={(e) => {}}
                    variant="contained"
                  >
                    {isLoadingEntry ? "loading" : "Return to Editor"}
                  </Button>
                )}

                {activeTab !== 1 && (
                  <Button
                    className="submit__btn draft__btn"
                    type="submit"
                    variant="contained"
                    onClick={(e) => handleFormSubmit(e, true, true)}
                  >
                    {isLoadingEntry ? "loading" : "APPROVE & UPLOAD"}
                  </Button>
                )}
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

const mapStateToProps = (state) => ({
  allJurisdictions: state.selectOptions.allJurisdictions,
  allSubJurisdictions: state.selectOptions.allSubJurisdictions,
});

const mapDispatchToProps = (dispatch) => ({
  getAllSubJurisdictions: (jurisdictionId) =>
    dispatch(getAllSubJurisdictions(jurisdictionId)),
  getLanguages: () => dispatch(getLanguages()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  getTags: () => dispatch(getTags()),
});
export default connect(mapStateToProps, mapDispatchToProps)(GenericForm);
