import {
  Breadcrumbs,
  Collapse,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import useSWR from "swr";
import { fetcher } from "../utils/functions";

import Skeleton from "@mui/material/Skeleton";
import { getUsers } from "../actions/users";
import DbLayout from "./DbLayout";
import DbEntry from "./DbEntry";
import moment from "moment";

const DbDrafts = ({ users, getUsers, myDrafts = true }) => {
  const [open, setOpen] = useState(null);

  useEffect(() => {
    if (!users || !users.length) getUsers();
  }, []);

  const PREFIX = "DbDrafts";

  const classes = {
    table: `${PREFIX}-table`,
    tableHead: `${PREFIX}-tableHead`,
    progress: `${PREFIX}-progress`,
    modalPaper: `${PREFIX}-modalPaper`,
    tableRow: `${PREFIX}-tableRow`,
    tableRowOpen: `${PREFIX}-tableRowOpen`,
  };

  // TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
  const Root = styled("div")({
    [`& .${classes.table}`]: {
      minWidth: 650,
      marginTop: 20,
    },
    [`& .${classes.tableHead}`]: {
      backgroundColor: "#ddd",
    },
    [`& .${classes.progress}`]: {
      position: "absolute",
      top: "60px",
      right: "20px",
    },
    [`& .${classes.modalPaper}`]: {
      width: "10%",
    },
    [`& .${classes.tableRow}`]: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f4f4f4",
      },
    },

    [`& .${classes.tableRowOpen}`]: {
      backgroundColor: "#f4f4f4",
    },
  });

  const {
    data: drafts,
    error: error,
    isLoading: loading,
    mutate,
  } = useSWR(
    process.env.REACT_APP_BACKEND_URL +
      "/database/drafts/" +
      (myDrafts ? 0 : 1),
    fetcher
  );

  const [selectedJurisdictionName, setSelectedJurisdictionName] = useState("");

  const [sortBy, setSortBy] = useState({ by: "id", desc: true });

  if (error) return <div>failed to load</div>;
  if (loading)
    return (
      <Container>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Skeleton
            sx={{ bgcolor: "grey.100" }}
            style={{ marginBottom: "20px" }}
            variant="rectangular"
            width={"calc(100vw - 300px)"}
            height={42}
          />
          <Skeleton
            sx={{ bgcolor: "grey.100" }}
            variant="rectangular"
            width={"calc(100vw - 300px)"}
            height={"calc(100vh - 310px)"}
          />
        </Paper>
      </Container>
    );

  return (
    <Root>
      <DbLayout>
        <div>
          <Container>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography variant="h6" color="text.primary">
                Drafts
              </Typography>
            </Breadcrumbs>
          </Container>
          {drafts && drafts.drafts && drafts.drafts.length === 0 && (
            <Container>
              <Typography variant="h6" gutterBottom>
                No drafts found
              </Typography>
            </Container>
          )}
          {drafts && drafts.drafts && drafts.drafts.length > 0 && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell
                      onClick={() =>
                        setSortBy({ by: "entry_type_id", desc: !sortBy.desc })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Area
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        setSortBy({ by: "jurisdiction_id", desc: !sortBy.desc })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Jurisdiction
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        setSortBy({ by: "updated_at", desc: !sortBy.desc })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Date Saved
                    </TableCell>
                    {!myDrafts && (
                      <TableCell
                        onClick={() =>
                          setSortBy({ by: "user_id", desc: !sortBy.desc })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Submitted By
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {drafts.drafts
                    .sort((a, b) => {
                      //Sort by entry_type_id and then by jurisdiction_id

                      if (a.entries.entry_type_id < b.entries.entry_type_id) {
                        return -1;
                      }
                      if (a.entries.entry_type_id > b.entries.entry_type_id) {
                        return 1;
                      }
                      if (
                        a.entries.jurisdictions.name_en <
                        b.entries.jurisdictions.name_en
                      ) {
                        return -1;
                      }
                      if (
                        a.entries.jurisdictions.name_en >
                        b.entries.jurisdictions.name_en
                      ) {
                        return 1;
                      }
                      if (
                        a.entries.subjurisdictions &&
                        a.entries.subjurisdictions
                      ) {
                        if (
                          a.entries.subjurisdictions.name_en <
                          b.entries.subjurisdictions.name_en
                        ) {
                          return -1;
                        }
                        if (
                          a.entries.subjurisdictions.name_en >
                          b.entries.subjurisdictions.name_en
                        ) {
                          return 1;
                        }
                      }
                      return 0;
                    })
                    .map((entry) => (
                      <>
                        <TableRow
                          key={entry.id}
                          onClick={() =>
                            open !== entry.id
                              ? setOpen(entry.id)
                              : setOpen(null)
                          }
                          className={
                            classes.tableRow +
                            " " +
                            (open === entry.id ? classes.tableRowOpen : "")
                          }
                        >
                          <TableCell>{entry.entries.entry_type_id}</TableCell>

                          <TableCell>
                            {entry.entries.jurisdictions.name_en}
                            {entry.entries.subjurisdictions &&
                            entry.entries.subjurisdictions.name_en
                              ? " - " + entry.entries.subjurisdictions.name_en
                              : ""}
                          </TableCell>

                          <TableCell>
                            {moment(entry.updated_at).format("DD MMMM YYYY") +
                              " " +
                              moment(entry.updated_at).format("HH:mm:ss")}
                          </TableCell>
                          {!myDrafts && (
                            <TableCell>
                              {users &&
                                users.find((u) => u.id === entry.user_id) &&
                                users.find((u) => u.id === entry.user_id).name}
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={6}
                          >
                            <Collapse
                              in={open === entry.id}
                              timeout="auto"
                              unmountOnExit
                            >
                              <DbEntry
                                entryTypeId={entry.entries.entry_type_id}
                                jurisdictionId={entry.entries.jurisdictions.id}
                                subjurisdictionId={
                                  entry.entries.subjurisdictions?.id
                                }
                                setSuccess={() => {}}
                                embedded={true}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </DbLayout>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  users: state.users.all,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => dispatch(getUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DbDrafts);
