import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import useSWR, { useSWRConfig } from "swr";
import { fetcher } from "../utils/functions";

import Skeleton from "@mui/material/Skeleton";
import {
  getAllDecrimMechanisms,
  getAllJurisdictions,
  getDeathPenaltyValues,
  getMaxPrisonValues,
  getOtherPenalties,
  getPenalties,
} from "../actions/selectOptions";
import { getUsers } from "../actions/users";
import { areas } from "../utils/functions";
import FullForm from "./Components/FullForm";
import axios from "axios";
import FullFormA13 from "./Components/FullFormA13";
import GenericForm from "./Components/GenericForm";
import { checkErrors, formConfig, initialFormState } from "./Config/area1";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ActionTriggerProvider } from "./ActionTriggerContext";

const DbEntry = ({
  entryTypeId,
  allJurisdictions,
  jurisdictionId,
  subjurisdictionId,
  getAllDecrimMechanisms,
  getPenalties,
  getOtherPenalties,
  getMaxPrisonValues,
  getDeathPenaltyValues,
  getAllJurisdictions,
  params,
  setSuccess,
  users,
  getUsers,
  embedded = false,
  fromRevisions = false,
}) => {
  const history = useHistory();
  useEffect(() => {
    getAllJurisdictions();
    if (entryTypeId === "A1-1") {
      getAllDecrimMechanisms();
      getPenalties();
      getOtherPenalties();
      getMaxPrisonValues();
      getDeathPenaltyValues();
    } else if (entryTypeId === "A1-3") {
      //Area 1-3 penalties
    }
    if (!users || !users.length) getUsers();
  }, []);

  const { mutate: globalMutate } = useSWRConfig();

  const {
    data: dataEntry,
    error: errorEntry,
    isLoading: isLoadingEntry,
    mutate,
  } = useSWR(
    process.env.REACT_APP_BACKEND_URL +
      "/database/entry/" +
      jurisdictionId +
      "/" +
      entryTypeId +
      "/" +
      (subjurisdictionId ? subjurisdictionId : 0) +
      "/" +
      (fromRevisions ? 0 : 0),
    fetcher
  );

  useEffect(() => {
    console.log("dataEntry: ", dataEntry);
  }, [dataEntry]);

  useEffect(() => {
    const fixError = async () => {
      try {
        await mutate();
      } catch (err) {
        console.log("err in mutate: ", err);
      }
    };
    fixError();
  }, [params]);

  const revertToOriginal = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          "/database/original_entry/" +
          jurisdictionId +
          "/" +
          entryTypeId +
          "/" +
          subjurisdictionId,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("data!!!!! ", res);
      if (res) {
        await mutate();
        globalMutate(
          process.env.REACT_APP_BACKEND_URL + "/database/jurisdictions"
        );
        globalMutate(process.env.REACT_APP_BACKEND_URL + "/database/drafts");
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const createEntryDraft = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          "/database/create_draft/" +
          jurisdictionId +
          "/" +
          entryTypeId +
          "/" +
          subjurisdictionId,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("data!!!!! ", res);
      if (res) {
        await mutate();
        globalMutate(
          process.env.REACT_APP_BACKEND_URL + "/database/jurisdictions"
        );
        globalMutate(process.env.REACT_APP_BACKEND_URL + "/database/drafts");
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const deleteEntryDraft = async () => {
    try {
      const res = await axios.delete(
        process.env.REACT_APP_BACKEND_URL +
          "/database/delete_draft/" +
          jurisdictionId +
          "/" +
          entryTypeId +
          "/" +
          subjurisdictionId,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("res: ", res);
      if (res) {
        await mutate();
        globalMutate(
          process.env.REACT_APP_BACKEND_URL + "/database/jurisdictions"
        );
        globalMutate(process.env.REACT_APP_BACKEND_URL + "/database/drafts");
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const [selectedJurisdictionName, setSelectedJurisdictionName] = useState("");

  useEffect(() => {
    const selectedJurName =
      allJurisdictions &&
      allJurisdictions.jurisdictions &&
      allJurisdictions.jurisdictions.length &&
      allJurisdictions.jurisdictions.filter(
        (jur) => jur.id === jurisdictionId
      )[0].name_en;
    setSelectedJurisdictionName(selectedJurName);
    console.log("data: ", dataEntry);
  }, [allJurisdictions, jurisdictionId]);

  function distributeEntries(entries) {
    const columns = 4; // Number of columns
    const rows = Math.ceil(entries.length / columns);

    // Create an array to hold the entries in column-first order
    let orderedEntries = [];
    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < columns; j++) {
        const index = j * rows + i;
        if (index < entries.length) {
          orderedEntries.push(entries[index]);
        }
      }
    }
    return orderedEntries;
  }

  if (errorEntry) return <div>failed to load</div>;
  if (isLoadingEntry)
    return (
      <Container>
        <Paper
          variant={!embedded ? "outlined" : ""}
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Skeleton
            sx={{ bgcolor: "grey.100" }}
            style={{ marginBottom: "20px" }}
            variant="rectangular"
            width={"calc(100vw - 300px)"}
            height={42}
          />
          <Skeleton
            sx={{ bgcolor: "grey.100" }}
            variant="rectangular"
            width={"calc(100vw - 300px)"}
            height={"calc(100vh - 310px)"}
          />
        </Paper>
      </Container>
    );

  const [area, subArea] = entryTypeId.split("-");

  return (
    <div>
      {!embedded && (
        <Container>
          <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: "16px" }}>
            <Typography color="text.primary">Area {area.charAt(1)}</Typography>
            <Typography color="text.primary">
              {areas[0].subareas.filter((area) => {
                const match = area.name.match(/^\d+/); // Match one or more digits at the start of the string
                return match && match[0] === subArea;
              })[0]
                ? areas[0].subareas.filter((area) => {
                    const match = area.name.match(/^\d+/);
                    return match && match[0] === subArea;
                  })[0].name
                : ""}
            </Typography>
            <Typography
              color="text.primary"
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push(
                  history.push(`/db-entry/${jurisdictionId}/${entryTypeId}/0`)
                )
              }
            >
              {selectedJurisdictionName}
            </Typography>{" "}
            {subjurisdictionId && parseInt(subjurisdictionId) !== 0 && (
              <Typography color="text.primary">
                {dataEntry.entry[0].entries.subjurisdictions.name_en}
              </Typography>
            )}
          </Breadcrumbs>
        </Container>
      )}
      {dataEntry && dataEntry.entry && dataEntry.entry[0] && (
        <Container>
          {dataEntry.entry[0].draft &&
            !dataEntry.entry[0].sent_for_revision && (
              <div className="draftDetails__div">
                Draft last saved by{" "}
                <strong>
                  {users &&
                    users.find((u) => u.id === dataEntry.entry[0].user_id) &&
                    users.find((u) => u.id === dataEntry.entry[0].user_id).name}
                </strong>{" "}
                on{" "}
                <strong>
                  {new Date(
                    dataEntry.entry[0].updated_at
                  ).toLocaleDateString() +
                    " " +
                    new Date(
                      dataEntry.entry[0].updated_at
                    ).toLocaleTimeString()}
                </strong>
                {!isLoadingEntry && (
                  <span
                    className="submit__btn discard__btn"
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      deleteEntryDraft();
                    }}
                  >
                    Discard
                  </span>
                )}
              </div>
            )}
          {dataEntry.entry[0].draft && dataEntry.entry[0].sent_for_revision && (
            <div className="draftDetails__div">
              Revision pending approval (last edit by{" "}
              <strong>
                {users &&
                  users.find((u) => u.id === dataEntry.entry[0].user_id) &&
                  users.find((u) => u.id === dataEntry.entry[0].user_id).name}
              </strong>{" "}
              on{" "}
              <strong>
                {new Date(dataEntry.entry[0].updated_at).toLocaleDateString() +
                  " " +
                  new Date(dataEntry.entry[0].updated_at).toLocaleTimeString()}
              </strong>
              )
            </div>
          )}
          <Paper
            variant={!embedded ? "outlined" : ""}
            className={!embedded ? "formPaper" : "formPaperRevisions"}
            sx={
              !embedded
                ? { my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }
                : { my: { xs: 3, md: 3 }, p: { xs: 2, md: 0 } }
            }
          >
            {" "}
            <div>
              {!embedded && !dataEntry.entry[0].draft && (
                <Button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    createEntryDraft();
                  }}
                  variant="contained"
                  style={{ float: "left", position: "absolute" }}
                >
                  Edit this entry
                </Button>
              )}
              {!fromRevisions &&
                !dataEntry.entry[0].draft &&
                dataEntry.originalEntry && (
                  <Button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      revertToOriginal();
                    }}
                    variant="contained"
                    style={{ float: "right" }}
                  >
                    Revert to original
                  </Button>
                )}
              {!embedded && (
                <Typography
                  component="h1"
                  variant="h4"
                  gutterBottom
                  align="center"
                >
                  {areas[0].subareas.filter(
                    (area) =>
                      area.name.substring(0, area.name.indexOf("-") - 1) ==
                      subArea
                  )[0]
                    ? areas[0].subareas.filter(
                        (area) =>
                          area.name.substring(0, area.name.indexOf("-") - 1) ==
                          subArea
                      )[0].name
                    : ""}
                  {" | "}
                  {selectedJurisdictionName}{" "}
                  {subjurisdictionId &&
                    parseInt(subjurisdictionId) !== 0 &&
                    `| ${dataEntry.entry[0].entries.subjurisdictions.name_en}`}
                  <span style={{ display: "none" }}>
                    (id: {dataEntry.entry[0].id})
                  </span>
                </Typography>
              )}
            </div>
            <ActionTriggerProvider>
              <GenericForm
                enabled={
                  dataEntry.entry[0].draft &&
                  !dataEntry.entry[0].sent_for_revision
                    ? true
                    : false
                }
                loadingJurisdictions={selectedJurisdictionName}
                dataEntry={dataEntry}
                jurisdictionId={jurisdictionId}
                subjurisdictionId={subjurisdictionId}
                entryTypeId={entryTypeId}
                mutate={mutate}
                setSuccess={setSuccess}
                users={users}
                initialFormState={initialFormState(entryTypeId)}
                form={formConfig(entryTypeId)}
                embedded={embedded}
                fromRevisions={fromRevisions}
                checkErrors={checkErrors(entryTypeId)}
              />
            </ActionTriggerProvider>
          </Paper>
          {dataEntry.entriesSubjurs.length > 0 && !embedded && (
            <Paper
              className="formPaper"
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Typography
                component="h1"
                variant="h5"
                display={{
                  marginBottom: "20px",
                  paddingBottom: "10px",
                  borderBottom: "1px solid gray",
                }}
                gutterBottom
                align="left"
              >
                Subjurisdictions
                {subjurisdictionId && parseInt(subjurisdictionId) !== 0 && (
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "10px",
                      cursor: "pointer",
                      float: "right",
                    }}
                    onClick={() => {
                      history.push(
                        `/db-entry/${jurisdictionId}/${entryTypeId}/0`
                      );
                    }}
                  >
                    {" "}
                    Go back to <strong>{selectedJurisdictionName}</strong>{" "}
                  </span>
                )}
              </Typography>

              <Grid container spacing={2}>
                {distributeEntries(
                  dataEntry.entriesSubjurs.filter((e) => e.entries)
                ).map((entry, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Link
                      onClick={() => {
                        history.push(
                          `/db-entry/${jurisdictionId}/${entryTypeId}/${entry.entries.subjurisdictions.id}`
                        );
                      }}
                      style={{ textDecoration: "none", cursor: "pointer" }}
                    >
                      {entry.entries.subjurisdictions.name_en}
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          )}
        </Container>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  allJurisdictions: state.selectOptions.allJurisdictions,
  users: state.users.all,
});

const mapDispatchToProps = (dispatch) => ({
  getAllDecrimMechanisms: () => dispatch(getAllDecrimMechanisms()),
  getPenalties: () => dispatch(getPenalties()),
  getOtherPenalties: () => dispatch(getOtherPenalties()),
  getMaxPrisonValues: () => dispatch(getMaxPrisonValues()),
  getDeathPenaltyValues: () => dispatch(getDeathPenaltyValues()),
  getAllJurisdictions: () => dispatch(getAllJurisdictions()),
  getUsers: () => dispatch(getUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DbEntry);
